import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Contact from './components/Contact'; // Import the Contact component
import Projects from './components/Projects';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update imports

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="App-main-page">
          <Routes> 
            <Route path="/" element={<Home />} /> 
            <Route path="/contact" element={<Contact />} /> 
            <Route path="/projects" element={<Projects />} /> 
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;

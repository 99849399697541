import './Projects.css';

const Projects = () => {
  return (
    <div className="App-projects" id="projects">
        <div className="App-projects-title">
            <h1>Projects</h1>
        </div>
        <div className="App-projects-details">
            {/* Left large rectangle */}
            <div className="App-projects-large-rect">
                <img src="./assets/huggingface.png" alt="Left Project" />
                <div className="project-overlay">
                    <p>Robotics</p>
                </div>
            </div>

            {/* Right side smaller rectangles */}
            <div className="App-projects-small-rect">
                <img src="./assets/transformer.png" alt="Top Right Project" />
                <div className="project-overlay">
                    <p>Finetuning</p>
                </div>
            </div>
            <div className="App-projects-small-rect">
                <img src="./assets/baptiste.png" alt="Bottom Right Project" />
                <div className="project-overlay">
                    <p>Websites</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Projects;


import './Contact.css'

const Contact = () => {
  return (
    <div className="App-contact" id="contact">
        <div className="App-contact-title">
            <h1>Let's work together!</h1>
        </div>
    <div className="App-contact-details">
        <h2>Email</h2>
        <p>charlotte.madrangeas@gmail.com</p>
        <h2>Phone</h2>
        <p>+33(0) 6 59 69 83 37</p>       
        <h2>GitHub</h2>
        <p>@chmadran</p>
    </div>
    </div>

  );
};

export default Contact;
import './Home.css';

const Home = () => {
  return (
    <div className="App">
    <div className="App-main-page">
      <h1>Hello there 👋</h1>
      <p>Welcome to my <span>portfolio</span>!</p>
    </div>
    </div>
  );
}

export default Home;
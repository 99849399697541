import './Navbar.css';
import { NavLink } from 'react-router-dom'; // Use NavLink instead of Link

const Navbar = () => {
  return (
    <div className="App-navbar-name">
      <h1>Charlotte Madrangeas</h1>
      <div className="App-navbar-links">
        <NavLink to="/" end className="navbar-link">About</NavLink> 
        <NavLink to="/projects" className="navbar-link">Projects</NavLink>
        <NavLink to="/contact" className="navbar-link">Contact</NavLink>
        <a href="https://github.com/chmadran" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/ios/50/000000/github.png" alt="github" />
        </a>
        <a href="https://www.linkedin.com/in/charlotte-madrangeas-25a021155/" target="_blank" rel="noopener noreferrer">
          <img src="https://img.icons8.com/ios/50/000000/linkedin.png" alt="linkedin" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
